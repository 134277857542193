export class CLIENTE {
    IDCLIENTE!: number
    NOME!: string
    DATA_DADASTRO!: string
    CPF!: string
    RG!: string
    DATA_NASCIMENTO!: string
    CELULAR!: string
    TELEFONE!: string
    TELEFONE2!: string
    LOGRADOURO!: string
    NUMERO!: string
    COMPLEMENTO!: string
    BAIRRO!: string
    CIDADE!: string
    CEP!: string
    EMAIL!: string
    OBS!: string
    UF!: string
}