import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ValidateBrService } from 'angular-validate-br';
import { QueryLogin } from '../model/login';
import { AlertService } from '../service/alert/alert.service';
import { ApiService } from '../service/api.service';
import { LoginService } from '../service/login/login.service';
import { Auth } from '../service/user/auth';
import { User } from '../service/user/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  formLogin!: FormGroup;
  loading: boolean = false;
  logado: boolean = false;
  sessionIndex = 0;
  tipUser: 'aluno' | 'empresa' = 'aluno';
  constructor(
    private router: Router,
    private service: LoginService,
    private formBuild: FormBuilder,
    private ValidBr: ValidateBrService,
    private api: ApiService,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    this.creatForm();
  }
  creatForm() {
    this.formLogin = this.formBuild.group({
      // user: new FormControl('EMPRESA TESTE', ([Validators.required])),
      // password: new FormControl('24941936000185', [Validators.required, Validators.minLength(8)])
      user: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
  }
  async login() {
    if (this.formLogin.invalid) {
      if (this.tipUser == 'empresa') {
        this.alert.creat(
          this.formLogin.get('password')?.invalid
            ? 'Usuário invalido'
            : 'Preencha os campos corretamente',
          'error'
        );
      } else {
        this.alert.creat(
          this.formLogin.get('user')?.invalid
            ? 'CPF ou PRN inválido'
            : this.formLogin.get('password')?.invalid
            ? 'Senha deve conter no mínimo 8 caracteres'
            : 'Preencha os campos corretamente',
          'error'
        );
      }
      return;
    }

    this.loading = true;
    const register = this.formLogin.value as QueryLogin;

    register.company = this.tipUser == 'empresa' ? true : false;

    await this.service
      .login(register)
      .toPromise()
      .then(async (res) => {
        if (!res.status) {
          this.alert.creat(res.message ? res.message : 'Erro', 'error');
          return;
        }
        const auth = new Auth();
        const user = new User();
        await auth.set(register);
        await user.set(res.result[0]);
        this.goToHome();
      })
      .catch((error) => {
        this.alert.creat('ERRO CONEXÃO COM SERVIDOR....', 'warning');
        console.log('error', error);
      });

    this.loading = false;
  }

  goToHome() {
    this.logado = true;
    // this.router.navigate(['home'])
  }
}
