import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { finalize, tap } from "rxjs/operators"
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ALUNOS, Certificados } from '../model/alunos';
import { CLIENTE } from '../model/cliente';
import { CURSOS } from '../model/cursos';
import { QueryLogin } from '../model/login';
import { AlertService } from '../service/alert/alert.service';
import { AlunosService } from '../service/alunos/alunos.service';
import { CursosService } from '../service/cursos/cursos.service';
import { DownloadService } from '../service/download/download.service';
import { Auth } from '../service/user/auth';
import { User } from '../service/user/user';
@Component({
  selector: 'app-home-company',
  templateUrl: './home-company.component.html',
  styleUrls: ['./home-company.component.scss']
})
export class HomeCompanyComponent implements OnInit {
  @Output('exit') exit = new EventEmitter
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  photoUser = { 'background': 'url(assets/company-avatar.jpg)' }
  viewFilter: boolean = false
  viewRegister: boolean = false;

  dataSource: MatTableDataSource<ALUNOS> = new MatTableDataSource()
  displayedColumns = ['IDCLIENTE', 'NOME', 'EMAIL', 'CERTIFICADOS']
  dataSourceUser = new MatTableDataSource()
  displayedColumnsUser = ['item_id', 'nameCertificado', 'start_time', 'end_time', 'status']
  register: any;
  user = new CLIENTE;
  loading: boolean = false;

  listCertificaodos: Certificados[] = []
  dataSource_backup: ALUNOS[] = [];
  fileIndex: number = 0;
  listCursos: CURSOS[] = [];
  filterData = { start: '', end: '' }
  constructor(
    private router: Router,
    private service: AlunosService,
    private _cursos: CursosService,
    private alert: AlertService,
    private _download: DownloadService,

  ) { }

  ngOnInit(): void {
    const auth = new Auth
    const user = new User
    if (!auth.get().user) {
      this.router.navigateByUrl('')
      return
    }

    this.user = user.get()

    this.getAlunos()
    this.getCursos()

  }

  async getAlunos() {
    this.loading = true
    await this.service.get().pipe(
      tap(res => {
        if (!res.status) {
          this.alert.creat('Não foi possivel listar alunos', 'error')
          return
        }
        this.dataSource = new MatTableDataSource(res.result)
        this.dataSource.sort = this.sort
        this.dataSource.paginator = this.paginator

        let getCursos: Certificados[] = []
        res.result.forEach(register => {
          if (register.CERTIFICADOS.length < 1) return;
          else
            getCursos.push(...register.CERTIFICADOS)
        })
        res.result.forEach(value => {
          value.CERTIFICADOS.forEach(cert => {
            cert.status = cert.status == 'A' ? 'Aprovado' : cert.status
          })
        })

        this.listCertificaodos = []
        getCursos.forEach(item => {
          let verific = this.listCertificaodos.findIndex(value => value.item_id == item.item_id)
          if (verific < 0) this.listCertificaodos.push(item)
        })
        this.dataSource_backup = [...res.result]
      }
      ),
      finalize(() => this.loading = false)
    ).subscribe()

  }
  getCursos() {
    this._cursos.get().toPromise()
      .then(res => {
        if (!res.status) return
        this.listCursos = res.result
      })
  }
  openRegister(register: ALUNOS) {
    this.viewRegister = true
    this.register = register
    this.dataSourceUser = new MatTableDataSource(this.register.CERTIFICADOS)
    console.log(this.register)
  }

  printTable() {

    const doc = new jsPDF('l', 'pt') as any;
    // doc.autoTable({ html: '#table' })
    doc.text(this.user.NOME, 20, 20);
    doc.setFontSize(10)
    doc.text("Alunos", 20, 33);
    // doc.text(cli, 40, doc.autoTable.previous.finalY + 15);

    this.dataSource.filteredData.forEach(value => {
      console.log('-->', doc.autoTable.previous)
      // if (doc.autoTable.previous) {
      //   doc.text(value.display_name, 40, doc.autoTable.previous.finalY + 15);
      // } else {
      //   doc.text(value.display_name, 40, 15);
      // }

      // doc.autoTable({
      //   theme: 'grid',
      //   columns: [
      //     { header: value.display_name, dataKey: 'display_name' },
      //     // { header: value.user_email, dataKey: 'user_email' }
      //   ],
      // })
      if (value.CERTIFICADOS.length) {
        doc.autoTable({
          body: this.getBodyTable([...value.CERTIFICADOS]),
          // theme: 'plain',
          theme: 'grid',
          columns: [
            { header: `${value.NOME}`, dataKey: 'nameCertificado' },
            { header: 'Inicio', dataKey: 'start_time' },
            { header: 'Termino', dataKey: 'end_time' },
            { header: 'Status', dataKey: 'status' },
          ],
        })
      }


    })
    // doc.autoTable({
    //   theme: 'grid',
    //   body: this.dataSource.filteredData,
    //   columns: [
    //     { header: 'Nome', dataKey: 'display_name' },
    //     { header: 'Email', dataKey: 'user_email' }
    //   ],
    // })

    doc.output('dataurlnewwindow', 'doc.pdf');
    this.getAlunos()
  }
  printTableUser() {
    const doc = new jsPDF('l', 'pt') as any;
    // doc.autoTable({ html: '#table' })
    doc.text(this.register.display_name, 20, 20);
    doc.setFontSize(10)
    doc.text(`${this.register.user_email}`, 20, 33);
    // doc.text(cli, 40, doc.autoTable.previous.finalY + 15);


    doc.autoTable({
      theme: 'grid',
      body: this.getBodyTable([...this.register.certificados]),
      columns: [
        { header: 'Certificado', dataKey: 'nameCertificado' },
        { header: 'Inicio', dataKey: 'start_time' },
        { header: 'Termino', dataKey: 'end_time' },
        { header: 'Status', dataKey: 'status' },
      ],
    })
    this.getAlunos()
    doc.output('dataurlnewwindow', 'doc.pdf');
  }
  getBodyTable(data: Array<any>) {
    data.forEach(value => {
      try {
        value.start_time = value.start_time.split('T')[0].split('-').reverse().join('/')
        value.end_time = value.end_time.split('T')[0].split('-').reverse().join('/')
      } catch {

      }
    })
    return data
  }
  async download(fileName: any, id: number) {
    if (this.loading) {
      this.alert.creat('Aguarde o download ser concluido...', 'warning')
      return
    }
    this.fileIndex = id
    let convert = fileName.toString() + '.pdf'
    this.loading = true

    await this._download.post(convert).toPromise()
      .then(res => {
        var blob = new Blob([res], { type: 'application/pdf' })
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        // link.download = fileName;
        // window.open(url, '_blank')
        link.click()
      })
      .catch(error => {
        if (error.status == 500) {
          this.alert.creat('Aguarde 24h para realizar o download deste certificado novamente', 'warning')
          return
        }
        if (error.status == 406) {
          this.alert.creat('Arquivo não encontrado!', 'error')
          return
        }
        this.alert.creat('Não foi possível realizar o download....', 'error')
      })
    this.loading = false
    this.fileIndex = 0

  }
  logout() {
    const auth = new Auth
    auth.set(new QueryLogin)
    // this.router.navigateByUrl('')
    this.exit.emit()
  }
  clearFilter() {
    this.viewFilter = false
    this.dataSource.filter = ''
    this.dataSource.data = this.dataSource_backup
    this.filterData.start = ''
    this.filterData.end = ''
  }
  filterName(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  filterCurso(event: Event, date?: boolean) {

    const key = (event.target as HTMLInputElement).value
    console.log(key)
    const all = this.dataSource_backup

    if (parseInt(key) == 0) {
      this.dataSource.data = all
      return
    }

    const register: ALUNOS[] = []
    let checked: any[] = []
    all.forEach(value => {
      const certificados = value.CERTIFICADOS
      if (certificados.length < 1) return

      if (date) {

        if (this.filterData.start && !this.filterData.end) {
          console.log('cheked 1', this.filterData)
          checked = certificados.filter(value => {
            let valueStart = value.start_time.split('T')[0].split('-').join('')
            let valueEnd = new Date(this.filterData.start).toISOString().split('T')[0].split('-').join('')
            return parseInt(valueStart) == parseInt(valueEnd)
          })
        }
        if (this.filterData.start && this.filterData.end) {
          console.log('cheked 2', this.filterData)
          checked = certificados.filter(value => {
            let valueStart = value.start_time.split('T')[0].split('-').join('')
            let filterStart = new Date(this.filterData.start).toISOString().split('T')[0].split('-').join('')
            return parseInt(valueStart) >= parseInt(filterStart)
          }).filter(value => {
            let valueEnd = value.end_time.split('T')[0].split('-').join('')
            let filterEnd = new Date(this.filterData.end).toISOString().split('T')[0].split('-').join('')
            return parseInt(valueEnd) <= parseInt(filterEnd)
          })
        }
      } else {
        checked = certificados.filter(value => value.nameCertificado.toLocaleLowerCase() == key.toLocaleLowerCase())
      }

      if (checked.length) register.push(value)

    })
    this.dataSource.data = register
    console.log('end ', register)
  }
}
