import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CURSOS } from 'src/app/model/cursos';
import { REQUEST } from 'src/app/model/request';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../api.service';
import { Auth } from '../user/auth';

@Injectable({
  providedIn: 'root'
})
export class CursosService {
  constructor(private http: HttpClient, private _api: ApiService) { }

  get() {
    const query = new Auth().get()
    console.log('passou!',this._api.url )
    const params = new HttpParams()
      .append('user', query.user)
      .append('password', query.password)
    return this.http.get<REQUEST<CURSOS>>(this._api.url + 'cursos',{params:params})
  }
}
