<mat-drawer-container autosize>
    <mat-toolbar class="navbar">
        <div class="avatar" [ngStyle]="photoUser">
        </div>
        <div class="infoUser">
            <div class="t-1">{{user.NOME}}</div>
            <div class="t-2">{{user.EMAIL}}</div>
            <div class="t-2 mt-1" *ngIf="!refresh"> {{certificados.length}} Certificados</div>
        </div>
        <div class="btn-exit">
            <button matTooltip="Atualizar" (click)="getCertificados()" mat-button>
                <mat-icon class="mat-18">replay</mat-icon>
            </button>
            <button matTooltip="Sair" (click)="logout()" mat-button>
                <mat-icon class="mat-18">logout</mat-icon>
            </button>
        </div>


    </mat-toolbar>
    <div class="container-fuild h-100">
        <div class="painel">
            <!-- <div class="input-group p-3 bg-white">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="my-addon">
                        <mat-icon class="mat-18">search</mat-icon>
                    </span>
                </div>
                <input class="form-control" type="text" name="" placeholder="Pesquisar..." aria-describedby="my-addon">
            </div> -->
            <div class="title-session">
                Meus Certificados
                <mat-progress-bar *ngIf="refresh" mode="indeterminate"></mat-progress-bar>
            </div>

            <div class="d-flex" style="flex-wrap: wrap;">
                <div class="item" *ngFor="let item of certificados" (click)="download(item.REF,item.IDCERTIFICADO);">
                    <img src="../../assets/certificado.png" alt="">
                    <div class="detalhe">
                        <div class="name">{{item.DESCRICAO}}</div>
                        <div class="d-flex" style="justify-content: flex-end;">
                            <mat-icon class="mat-18" style="font-size: 20px;">event</mat-icon> {{item.DATA_ALTERACAO |
                            date : 'dd/MM/yyyy'}}
                        </div>
                        <div class="detalhe-menu">
                            <!-- <button [disabled]="loading" [hidden]="loading && fileIndex == item.IDCERTIFICADO" mat-button class="text-success"
                                (click)="download(item.REF);fileIndex = item.IDCERTIFICADO">
                                <mat-icon class="mat-18">file_download</mat-icon> Download
                            </button> -->
                            <img class="loading" *ngIf="loading && fileIndex == item.IDCERTIFICADO"
                                src="assets/loading.gif" alt="">
                            <!-- <button mat-button class="text-info">
                                <mat-icon class="mat-18">visibility</mat-icon>
                            </button> -->
                        </div>
                    </div>
                </div>
                <div [hidden]="refresh" class="noItem" *ngIf="certificados.length < 1">
                    <img src="assets/noCertificado.png" alt="" hidden>
                    <div class="w-50 text-white">Você não possui nenhum certificado no momento</div>
                </div>
            </div>
        </div>
    </div>



</mat-drawer-container>
<!-- <pdf-viewer [src]="pdfSrc" [render-text]="true" [autoresize]="true" style="display: block;width: 100%;height: 100%;"></pdf-viewer> -->