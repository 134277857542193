import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private _snackBar: MatSnackBar) {

  }
  creat(title: string, type: 'error' | 'warning' | 'success') {
    this._snackBar.open(title, 'x', {
      horizontalPosition: 'center', 
      verticalPosition: 'bottom',
      panelClass: ['alert', `alert-${type}`],
      duration: 3000
    })
  }
}
