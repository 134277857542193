import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HttpClientModule } from '@angular/common/http';
import { LoginService } from './service/login/login.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidateBrService } from 'angular-validate-br';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AlertService } from './service/alert/alert.service';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { CertificadoService } from './service/certificado/certificado.service';
import { DownloadService } from './service/download/download.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ApiService } from './service/api.service';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import { HomeCompanyComponent } from './home-company/home-company.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import { AlunosService } from './service/alunos/alunos.service';
import { CursosService } from './service/cursos/cursos.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HomeCompanyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    PdfViewerModule,
    HttpClientModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatTableModule,
    MatTabsModule,
    MatSortModule,
    MatSlideToggleModule,
    NgxMaskModule.forRoot(options)
  ],
  providers: [
    LoginService,
    ValidateBrService,
    AlertService,
    CertificadoService,
    DownloadService,
    ApiService,
    AlunosService,
    CursosService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
