<div class="container" *ngIf="!logado">
  <mat-card class="formLogin">
    <mat-card-content>
      <div class="background"></div>
      <div class="menuTipUser">
        <div
          class="select {{ tipUser == 'aluno' ? 'menuTipUser-selcet' : '' }}"
          (click)="sessionIndex = 0; tipUser = 'aluno'"
        >
          <mat-icon class="mat-18 ms-2">school</mat-icon> Aluno
        </div>
        <div
          class="select {{ tipUser == 'empresa' ? 'menuTipUser-selcet' : '' }}"
          (click)="sessionIndex = 1; tipUser = 'empresa'"
        >
          <mat-icon class="mat-18 ms-2">work_outline</mat-icon> Empresa
        </div>
      </div>
      <mat-tab-group [selectedIndex]="sessionIndex" [disableRipple]="true">
        <mat-tab disabled *ngFor="let i of [0, 1]">
          <form class="row w-100" [formGroup]="formLogin">
            <div class="col-12 login">
              <div class="form-group">
                <label for="my-input">{{
                  sessionIndex == 0 ? "CPF ou PRN" : "Usuario"
                }}</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="my-addon">
                      <mat-icon
                        aria-hidden="false"
                        aria-label="Example home icon"
                        >assignment_ind
                      </mat-icon>
                    </span>
                  </div>
                  <input
                    formControlName="user"
                    class="form-control"
                    type="text"
                    name=""
                    [placeholder]="sessionIndex == 0 ? '000.000.000-00' : ''"
                    [mask]="
                      sessionIndex == 0
                        ? formLogin.get('user')?.value.length > 8
                          ? '000.000.000-00'
                          : ''
                        : ''
                    "
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="my-input">Senha</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="my-addon">
                      <mat-icon
                        aria-hidden="false"
                        aria-label="Example home icon"
                        >lock</mat-icon
                      >
                    </span>
                  </div>
                  <input
                    formControlName="password"
                    class="form-control"
                    type="password"
                    name=""
                    placeholder="******"
                  />
                </div>
              </div>
            </div>
          </form>
        </mat-tab>
      </mat-tab-group>
      <form class="row w-100">
        <div class="col-12 d-flex">
          <button *ngIf="!loading" (click)="login()" mat-button class="m-auto">
            <mat-icon class="mat-18">login</mat-icon>
            Entrar
          </button>
        </div>
        <img class="loading" src="assets/loading.gif" alt="" *ngIf="loading" />
      </form>
    </mat-card-content>
  </mat-card>
</div>
<app-home
  *ngIf="logado && tipUser == 'aluno'"
  (exit)="logado = false"
></app-home>
<app-home-company
  *ngIf="logado && tipUser == 'empresa'"
  (exit)="logado = false"
></app-home-company>
