import { EventEmitter } from '@angular/core';
import { AfterViewInit, Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CERTIFICADO } from '../model/certificado';
import { CLIENTE } from '../model/cliente';
import { QueryLogin } from '../model/login';
import { AlertService } from '../service/alert/alert.service';
import { CertificadoService } from '../service/certificado/certificado.service';
import { DownloadService } from '../service/download/download.service';
import { Auth } from '../service/user/auth';
import { User } from '../service/user/user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @Output('exit') exit = new EventEmitter
  pdfSrc = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  photoUser = { 'background': 'url(assets/user.svg)' }
  certificados: CERTIFICADO[] = [];
  user = new CLIENTE;
  loading: boolean = false;
  refresh: boolean = false;
  fileIndex = 0


  constructor(
    private router: Router,
    private _certificado: CertificadoService,
    private _download: DownloadService,
    private alert: AlertService) { }

  ngOnInit(): void {
    const auth = new Auth
    const user = new User
    if (!auth.get().user) {
      this.router.navigateByUrl('')
      return
    }
    this.user = user.get()
    this.refresh = true
    setTimeout(() => { this.getCertificados() }, 1000)

  }

  async getCertificados() {
    this.refresh = true

    await this._certificado.get().subscribe(
      resp => {
        if (resp.status) {
          this.certificados = resp.result
          this.refresh = false
          // console.log(this.certificados)
        }
      },
      error => {
        console.log('erro!', error)
        this.alert.creat('No momento não foi possível listar seus certificados', 'error')
        this.refresh = false
      }
    )
  }
  async download(fileName: any, id: number) {
    if (this.loading) {
      this.alert.creat('Aguarde o download ser concluido...', 'warning')
      return
    }
    this.fileIndex = id
    let convert = fileName.toString() + '.pdf'
    this.loading = true

    await this._download.post(convert).toPromise()
      .then(res => {
        var blob = new Blob([res], { type: 'application/pdf' })
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        // link.download = fileName;
        // window.open(url, '_blank')
        link.click()
      })
      .catch(error => {
        if (error.status == 500) {
          this.alert.creat('Aguarde 24h para realizar o download deste certificado novamente', 'warning')
          return
        }
        if (error.status == 406) {
          this.alert.creat('Arquivo não encontrado!', 'error')
          return
        }
        this.alert.creat('Não foi possível realizar o download....', 'error')
      })
    this.loading = false

  }
  logout() {
    const auth = new Auth
    auth.set(new QueryLogin)
    // this.router.navigateByUrl('')
    this.exit.emit()
  }

}
