import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { CERTIFICADO } from 'src/app/model/certificado';
import { REQUEST } from 'src/app/model/request';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../api.service';
import { Auth } from '../user/auth';

@Injectable({
  providedIn: 'root'
})
export class CertificadoService {
  api = ''
  constructor(private http: HttpClient, private _api: ApiService) { this.getAPI() }

  async getAPI() {
    this.api = await this._api.getAPI() 
  }

  get(): Observable<REQUEST<CERTIFICADO>> {
    const auth = new Auth().get()
    const param = new HttpParams()
      .append('user', auth.user)
      .append('password', auth.password)
    return this.http.get<any>(this.api + 'certificado', { params: param })
      .pipe(
        retry(5)
      )
  }
}
