import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../api.service';
import { Auth } from '../user/auth';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  api = ''
  constructor(private http: HttpClient, private _api: ApiService) { this.getAPI() }

  async getAPI() {
    this.api = await this._api.getAPI() 
  }


  post(fileName: string) {
    const auth = new Auth().get()
    const param = new HttpParams()
      .append('user', auth.user)
      .append('password', auth.password)
    return this.http.post(this.api + 'download/', { fileName: fileName }, { params: param, responseType: 'blob' })
  }
}
