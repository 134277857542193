import { CLIENTE } from "src/app/model/cliente"

export class User {

    constructor() {

    }
    set(query: CLIENTE) {
        return new Promise((result, reject) => {
         
            sessionStorage.setItem('user', JSON.stringify(query))
            result(true)
        })

    }
    get(): CLIENTE {
        const get = sessionStorage.getItem('user')
        return JSON.parse(get ? get : '{}')
    }
}