import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ALUNOS } from 'src/app/model/alunos';
import { CLIENTE } from 'src/app/model/cliente';
import { QueryLogin } from 'src/app/model/login';
import { REQUEST } from 'src/app/model/request';
import { ApiService } from '../api.service';
import { Auth } from '../user/auth';

@Injectable({
  providedIn: 'root'
})
export class AlunosService {

  constructor(private http: HttpClient, private _api: ApiService) { }

 

  get(): Observable<REQUEST<ALUNOS>> {
    const query = new Auth().get()
    const params = new HttpParams()
      .append('user', query.user)
      .append('password', query.password)

    return this.http.get<REQUEST<ALUNOS>>(this._api.url + 'alunos/', { params: params })

  }
}
