import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CLIENTE } from 'src/app/model/cliente';
import { QueryLogin } from 'src/app/model/login';
import { REQUEST } from 'src/app/model/request';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../api.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  api = ''
  constructor(private http: HttpClient, private _api: ApiService) { this.getAPI() }

  async getAPI() {
    this.api = await this._api.getAPI()
  }

  login(query: QueryLogin): Observable<REQUEST<CLIENTE>> {
    return this.http.post<REQUEST<CLIENTE>>(this.api + 'login/', query)

  }
}
