import { QueryLogin } from "src/app/model/login"

var $auth = new QueryLogin
export class Auth {
    auth = $auth
    constructor() {

    }
    set(query: QueryLogin) {
        sessionStorage.setItem('auth', JSON.stringify(query))
    }
    get(): QueryLogin {
        const get = sessionStorage.getItem('auth')
        return JSON.parse(get ? get : '{}')
    }
}