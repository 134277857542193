import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url: string = ''
  constructor(private http: HttpClient) {

  }
  async getAPI(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get<{ api: string }>('assets/config.json').toPromise()
        .then(res => resolve(res.api ? res.api : ''))
        .catch(() => reject(''))
    })
  }
  async get() {
    await this.http.get<{ api: string }>('assets/config.json').toPromise()
      .then(res => this.url = res.api ? res.api : '')
      .catch(() => this.url = '')

  }
}
