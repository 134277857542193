<mat-toolbar class="navbar">
    <div class="avatar" [ngStyle]="photoUser">
    </div>
    <div class="infoUser">
        {{user.NOME}}
    </div>
    <div class="btn-exit">
        <button matTooltip="Atualizar" (click)="getAlunos()" mat-button>
            <mat-icon class="mat-18">replay</mat-icon>
        </button>
        <button matTooltip="Sair" (click)="logout()" mat-button>
            <mat-icon class="mat-18">logout</mat-icon>
        </button>
    </div>


</mat-toolbar>
<div class="container-fuild" style="height: calc(100% - 65px);">
    <div class="painel">
        <!-- <div class="input-group p-3 bg-white">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="my-addon">
                        <mat-icon class="mat-18">search</mat-icon>
                    </span>
                </div>
                <input class="form-control" type="text" name="" placeholder="Pesquisar..." aria-describedby="my-addon">
            </div> -->
        <div class="title-session" style="line-height: 2;">
            Alunos
            <!-- <mat-progress-bar *ngIf="refresh" mode="indeterminate"></mat-progress-bar> -->
            <button (click)="viewFilter = !viewFilter" mat-button class="float-end" matTooltip="Filtrar"
                style="z-index: 9;">
                <mat-icon class="mat-18 text-white">
                    filter_alt
                </mat-icon>
            </button>
            <button (click)="printTable()" mat-button class="float-end" matTooltip="Imprimir">
                <mat-icon class="mat-18">
                    print
                </mat-icon>
            </button>

        </div>
        <div class="card mb-2" *ngIf="viewFilter">
            <div class="card-header d-flex" style="line-height: 2;">
                <mat-icon class="mat-18">
                    filter_alt
                </mat-icon> Filtro
            </div>
            <div class="card-body">
                <div class="row" style="font-size: 12px;">
                    <div class="col-12 col-md-5 col-lg-5">
                        <div class="form-group">
                            <label for="my-input">Nome</label>
                            <input (keyup)="filterName($event)" class="form-control" type="text" name="">
                        </div>
                    </div>
                    <div class="col-12 col-md-3 col-lg-3 ">
                        <div class="form-group">
                            <label for="my-input">Curso</label>
                            <select (change)="filterCurso($event)" id="my-select" class="form-control" name="">
                                <option [value]="0">Todos</option>
                                <option [value]="curso.NOME" *ngFor="let curso of listCursos">
                                    {{curso.NOME}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-8 col-md-3 col-lg-2">
                        <div class="form-group">
                            <label for="my-input">Data Incio</label>
                            <input (blur)="filterCurso($event,true)" (change)="filterCurso($event,true)" [(ngModel)]="filterData.start"  class="form-control" type="date" name="">
                        </div>
                    </div>
                    <div class="col-8 col-md-3 col-lg-2">
                        <div class="form-group">
                            <label for="my-input">Data Termino</label>
                            <input (blur)="filterCurso($event,true)" (change)="filterCurso($event,true)"  [(ngModel)]="filterData.end"  class="form-control" type="date" name="">
                        </div>
                    </div>
                    <div class="col-12">
                        <button (click)="clearFilter()" class="btn me-2 d-flex float-end">
                            <mat-icon class="mat-18">close</mat-icon>
                            Limpar filtro
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="table">
            <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100" matSort>

                <ng-container matColumnDef="IDCLIENTE">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let element" style="width: 100px;">
                        <button (click)="openRegister(element)" mat-button class="text-secondary">
                            <mat-icon class="mat-18">source</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="NOME">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Aluno </th>
                    <td mat-cell *matCellDef="let element"> {{element.NOME}} </td>
                </ng-container>
                <ng-container matColumnDef="EMAIL">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                    <td mat-cell *matCellDef="let element"> {{element.EMAIL}} </td>
                </ng-container>
                <ng-container matColumnDef="CERTIFICADOS">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Certificados </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="d-flex text-success" *ngIf="element.CERTIFICADOS.length > 0">
                            <mat-icon class="mat-18 me-2">card_membership</mat-icon>
                            {{element.CERTIFICADOS.length}}
                            {{element.CERTIFICADOS.length > 1 ? 'Certificados' : 'Certificado'}}
                        </div>
                        <div class="d-flex text-danger" *ngIf="element.CERTIFICADOS.length < 1">
                            <mat-icon class="mat-18 me-2">card_membership</mat-icon>
                            Não possui certificado
                        </div>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div class="popUp animated fadeIn" *ngIf="viewRegister">
                <mat-card>
                    <mat-card-header class="w-100 border-bottom">
                        <mat-card-title class="w-100">
                            <div class="info-header text-secondary">
                                <div> <img src="../../assets/user.png" alt=""></div>
                                <div class="info-header-text">
                                    <div>{{register.display_name}}</div>
                                    <div><strong> Email :</strong> {{register.EMAIL}}</div>
                                    <div><strong>Certificados :</strong> {{register.CERTIFICADOS.length}}</div>
                                </div>
                                <button (click)="printTableUser()" mat-button class="float-end" matTooltip="Imprimir">
                                    <mat-icon class="mat-18">
                                        print
                                    </mat-icon>
                                </button>
                                <button (click)="viewRegister = false" mat-button class="float-end">
                                    <mat-icon class="mat-18">close</mat-icon>
                                </button>

                            </div>

                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <table style="box-shadow: none;" mat-table [dataSource]="dataSourceUser"
                            class="mat-elevation-z8 w-100" matSort>


                            <ng-container matColumnDef="item_id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                <td mat-cell *matCellDef="let element"> {{element.item_id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="nameCertificado">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Certificado </th>
                                <td mat-cell *matCellDef="let element"> {{element.nameCertificado}} </td>
                            </ng-container>


                            <ng-container matColumnDef="start_time">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Inicio </th>
                                <td mat-cell *matCellDef="let element"> {{element.start_time | date : 'dd/MM/yyyy'}}
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="end_time">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Termino </th>
                                <td mat-cell *matCellDef="let element"> {{element.end_time | date : 'dd/MM/yyyy'}}
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.status}}
                                    <button mat-button [disabled]="loading"
                                        (click)="download(element.ref_id,element.parent_id)"
                                        class="text-success float-end" *ngIf="element.ref_type == 'mendes'">

                                        <mat-icon [hidden]="fileIndex == element.parent_id" class="mat-18">
                                            file_download</mat-icon>
                                        <img *ngIf="loading && fileIndex == element.parent_id" width="15px"
                                            src="assets/loading.gif" alt="">

                                    </button>

                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumnsUser"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsUser;"></tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>

        </div>
        <mat-paginator [pageSizeOptions]="[15, 20, 50]" aria-label="Select page of users"></mat-paginator>

    </div>
</div>




<!-- <pdf-viewer [src]="pdfSrc" [render-text]="true" [autoresize]="true" style="display: block;width: 100%;height: 100%;"></pdf-viewer> -->